<template>
    <div id="newFloorPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="newFloor"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="b&f_createNewFloorSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon kt-svg-icon--success">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon id="Bound" points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
                                                            <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" id="Path" fill="#000000" opacity="0.3"></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("b&f_createNewFloor") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form id="newFloorForm" class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="kt-section">
                                                    <h3 class="kt-section__title">1. {{ $t("b&f_floorInfo") }}</h3>
                                                    <div class="form-group validated row">
                                                        <div class="col-lg-6">
                                                            <label for="newFloorForm_nameInput">{{ $t("b&f_floorName") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                </div>
                                                                <input v-model="name" @input="$v.name.$touch()" type="text" class="form-control" id="newFloorForm_nameInput" :placeholder="$t('b&f_floorNameInputInfo')" />
                                                            </div>
                                                            <div v-if="!$v.name.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("b&f_floorNameDetailInfo") }}</span>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="">{{ $t("b&f_floorNumber") }} *</label>
                                                            <select id="newFloorFloorNumberSelect" class="form-control kt-select2">
                                                                <!-- To display placeholder -->
                                                                <option></option>
                                                            </select>
                                                            <div v-if="!$v.floorNumber.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("b&f_floorNumberDetailInfo") }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group validated">
                                                        <label for="newFloorDescriptionTextArea">{{ $t("b&f_floorDescription") }}</label>
                                                        <textarea id="newFloorDescriptionTextArea" class="form-control" rows="3" v-model="description"></textarea>
                                                        <span class="form-text text-muted">{{ $t("b&f_floorDescriptionDetailInfo") }}</span>
                                                    </div>
                                                    <div class="form-group form-group-last validated row">
                                                        <div class="col-lg-6">
                                                            <label for="newFloorForm_relativeAltitudeInput">{{ $t("b&f_floorRelativeAltitude") }}</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-upload"></i></span>
                                                                </div>
                                                                <span>
                                                                    <input v-model="relativeAltitude" id="newFloorForm_relativeAltitudeInput" type="text" value="" class="form-control" />
                                                                </span>
                                                            </div>
                                                            <div v-if="!$v.relativeAltitude.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-if="!$v.relativeAltitude.decimal" class="invalid-feedback">
                                                                {{ $t("error_invalidNumber") }}
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("b&f_floorRelativeAltitudeDetailInfo") }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>
                                                <div class="kt-section">
                                                    <h3 class="kt-section__title">2. {{ $t("b&f_floorMap") }}</h3>
                                                    <div class="form-group validated row">
                                                        <div class="col-lg-6">
                                                            <label>{{ $t("common_fileBrowser") }}</label>
                                                            <div class="custom-file">
                                                                <input type="file" @change="handleFileSelected" ref="imageFloorFile" :lang="this.$i18n.locale" class="custom-file-input cursorPointer" accept="image/png" id="selectFloorImageInputFile" />
                                                                <label class="custom-file-label textLeft" id="selectFloorImageInputLabel" for="customFile">{{ $t("common_chooseFile") }}</label>
                                                                <span class="form-text text-muted">{{ $t("b&f_floorFileDetailInfo") }}</span>
                                                            </div>
                                                            <div v-if="!isMapUploaded" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                        </div>
                                                        <div v-show="isMapUploaded" class="col-lg-6">
                                                            <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + this.currentFloorMap + ')' }"></div>
                                                            <div class="kt-margin-b-10 kt-margin-t-10">{{ mapFileName }}</div>
                                                            <div>
                                                                <button id="newFloorRemoveImageButton" type="button" @click="onRemoveImage" class="btn btn-outline-brand">{{ $t("common_remove") }}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="isMapUploaded" class="row">
                                                        <div class="col-lg-12">
                                                            <app-locationmap ref="map" mode="floorMapping"></app-locationmap>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <label class="kt-checkbox kt-margin-r-20">
                                                        <input v-model="createAnother" type="checkbox" /> {{ $t("common_createAnother") }}
                                                        <span></span>
                                                    </label>
                                                    <button id="newFloorCreateButton" @click="onCreateButton" type="button" :disabled="$v.$invalid" class="btn btn-brand kt-margin-r-5">{{ $t("common_add") }}</button>
                                                    <button id="newFloorCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <app-gofullscreenmodal></app-gofullscreenmodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from "../../i18n";
const _floors = require("../../constants/floors");
import { required, decimal } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import LocationMap from "../map/locationmap.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import GoFullscreenModal from "../modals/gofullscreenmodal.vue";
import { setTimeout } from "timers";

export default {
    data() {
        return {
            name: "",
            floorNumber: "",
            description: "",
            relativeAltitude: 0,
            siteId: this.$route.params.siteId,
            buildingId: this.$route.params.buildingId,
            mapFileName: "",
            invalidMapFormat: false,
            isMapUploaded: false,
            createAnother: false,
            imageFile: null
        };
    },
    created: function() {
        console.log("Component(newFloor)::created() - called");
        this.createAnother = this.createAnotherFloor;
        this.setCreateAnotherFloor(false);
    },
    mounted: function() {
        console.log("Component(newFloor)::mounted() - Init metronic layout");
        KTLayout.init();

        this.initFloorsSelect2();

        // initialize touchspin buttons on altitude input field
        $("#newFloorForm_relativeAltitudeInput")
            .TouchSpin({
                buttondown_class: "btn btn-secondary",
                buttonup_class: "btn btn-secondary",
                verticalbuttons: true,
                verticalup: '<i class="la la-angle-up"></i>',
                verticaldown: '<i class="la la-angle-down"></i>',
                step: 0.01,
                min: -20,
                decimals: 2
            })
            .on("change", () => {
                this.relativeAltitude = $("#newFloorForm_relativeAltitudeInput").val();
            });
    },
    destroyed: function() {
        console.log("Component(newFloor)::destroyed() - called");
        this.unsetCurrentFloor();
        let nextRoute = this.$router.currentRoute.name;
        if (nextRoute !== "floors") {
            this.resetBuildingsState();
        }
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        name: {
            required
        },
        floorNumber: {
            required
        },
        relativeAltitude: {
            decimal,
            required
        },
        isMapUploaded: {
            defined: value => value === true
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        user: function(user) {
            console.log("Component(newFloor)::watch(user) - called with ", user);
            if (user) {
                // In case of language change
                commonVueHelper.resetImageInputLabel(this.isMapUploaded);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentSite", "currentFloorMap", "createAnotherFloor", "user"]),

        floors: function() {
            return _.range(_floors.min, _floors.max + 1);
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["uploadFloorImage", "setFloorMap", "unsetCurrentFloor", "resetBuildingsState", "unsetFloorMap", "setCreateAnotherFloor", "createFloor"]),

        // Function called to init floors select2
        initFloorsSelect2() {
            let self = this;
            // Init unit Select2
            commonVueHelper.destroySelect2($("#newFloorFloorNumberSelect"));
            $("#newFloorFloorNumberSelect")
                .select2({
                    placeholder: i18n.t("b&f_floorNumberInputInfo"),
                    data: this.floors,
                    width: "100%",
                    minimumResultsForSearch: -1
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.floorNumber = Number($(this).val());
                });
            $("#newFloorFloorNumberSelect").on("select2:opening", function() {
                // init selected value to 0 if not already set
                if (self.floorNumber === "") {
                    $("#newFloorFloorNumberSelect")
                        .val(0)
                        .trigger("change");
                    let $select2 = $("#newFloorFloorNumberSelect").data("select2");
                    setTimeout(function() {
                        if (!$select2.isOpen()) {
                            $select2.open();
                        }
                    }, 0);
                }
            });
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(newFloor)::onCancelButton() - called");
            this.$router.push({ name: "floors", params: { buildingId: this.buildingId, siteId: this.siteId } });
        },

        onCreateButton() {
            console.log("Component(newFloor)::onCreateButton() - called");

            // get mapCoordinates
            let mapCoordinates = this.$refs.map.floorImageOverlay.getGeoJsonMapCoordinates();

            // const payload = {
            //     siteId: this.siteId,
            //     buildingId: this.buildingId,
            //     name: this.name,
            //     description: this.description,
            //     floorNumber: this.floorNumber,
            //     relativeAltitude: Number(this.relativeAltitude),
            //     map: this.currentFloorMap,
            //     mapFileName: this.mapFileName,
            //     mapCoordinates: mapCoordinates,
            //     createAnother: this.createAnother
            // };

            // this.createFloor(payload);

            const payload = {
                nextAction: "NEW_FLOOR",
                floorImage: this.imageFile,
                metadata: {
                    siteId: this.siteId,
                    buildingId: this.buildingId,
                    name: this.name,
                    description: this.description,
                    floorNumber: this.floorNumber,
                    relativeAltitude: Number(this.relativeAltitude),
                    map: this.currentFloorMap,
                    mapFileName: this.mapFileName,
                    mapCoordinates: mapCoordinates,
                    createAnother: this.createAnother
                }
            };

            this.uploadFloorImage(payload);
        },

        // Function called when user select an image
        handleFileSelected() {
            console.log("Component(newFloor)::handleFileSelected() - called");

            if (this.$refs.imageFloorFile.files.length > 0) {
                let imgSelectedFile = this.$refs.imageFloorFile.files[0];
                // Unset previous loaded image
                this.unsetFloorMap();
                this.mapFileName = "";
                this.isMapUploaded = false;
                // Load selected image
                if (imgSelectedFile.size > 3145728) {
                    // 3 MB max
                    alert(i18n.t("common_fileTooBig", { fileLimit: "3" }));
                    this.unsetFloorMap();
                    this.mapFileName = "";
                    this.isMapUploaded = false;
                } else {
                    this.imageFile = imgSelectedFile;
                    this.mapFileName = this.imageFile.name;
                    let reader = new FileReader();
                    reader.readAsDataURL(this.imageFile);
                    reader.onload = e => {
                        this.setFloorMap(e.target.result);
                        this.isMapUploaded = true;
                        this.$v.isMapUploaded.$touch();
                        $("#goFullscreenModal").modal("show");
                    };
                }
            } else {
                $("#selectFloorImageInputLabel").text(i18n.t("common_chooseFile"));
            }
        },

        // Function called when user click on the "Remove" button to remove image
        onRemoveImage() {
            this.mapFileName = "";
            this.isMapUploaded = false;
            this.$v.isMapUploaded.$touch();
            // Force clear of input file to be able to select the same image again
            $("#selectFloorImageInputFile").val(null);
            // Replace removed file path label by common_chooseFile text
            commonVueHelper.resetImageInputLabel();
            // Remove map and coordinates information from vuex store
            this.unsetFloorMap();
        },

        isEmptyForm() {
            // Return true if all control form are empty
            let isEmptyForm = true;
            if (this.name || this.floorNumber || this.description || this.relativeAltitude || this.mapFileName) {
                isEmptyForm = false;
            }
            return isEmptyForm;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-locationmap": LocationMap,
        "app-sitetitle": SiteTitle,
        "app-gofullscreenmodal": GoFullscreenModal
    },

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        commonVueHelper.displayConfirmModalOnLeavePage(this, "NewFloor", "create", !this.isEmptyForm(), to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}

.imagePreview {
    height: 108px;
    width: 160px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #ebedf2;
}
</style>
